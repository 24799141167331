// extracted by mini-css-extract-plugin
export var productDiscoverTestimonialSection = "w_df";
export var productDiscoveryAchievementsSection = "w_ds";
export var productDiscoveryBenefitsSection = "w_dd";
export var productDiscoveryCaseSection = "w_dm";
export var productDiscoveryClientSection = "w_dt";
export var productDiscoveryIndustriesSection = "w_dc";
export var productDiscoveryOverviewSection = "w_dk";
export var productDiscoveryProcessSection = "w_dj";
export var productDiscoveryProjLogoSection = "w_dr";
export var productDiscoveryResultsSection = "w_dn";
export var productDiscoveryRolesSection = "w_dl";
export var productDiscoveryServicesSection = "w_db";
export var productDiscoveryStagesSection = "w_dg";
export var productDiscoverySuccessStoriesSection = "w_dp";
export var productDiscoveryWhyChooseSection = "w_dq";
export var productDiscroveryImportanceSection = "w_dh";